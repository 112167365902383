export const tipoProductoPlanilla = [
  { id: "PRODUCTO_ENTRADA", name: "Producto de Entrada" },
  { id: "INSUMO", name: "Insumo" },
  { id: "MATERIAL_EMPAQUE", name: "Material de Empaque" },
  { id: "MAQUINARIA", name: "Maquinaria" },
  { id: "PRODUCTO_SALIDA", name: "Productos de Salida" },
  { id: "SUBPRODUCTO_SALIDA", name: "Subproducto de Salida" },
  { id: "EMPLEADO", name: "Empleado" },
];

export function findTipoProductoPlanilla(tpProductoP) {
  return tipoProductoPlanilla.find(
    (tpProducto) => tpProducto.id === tpProductoP
  ).name;
}

export const getordenesTrabajoStatus = {
  GRA: "Grabada",
  REV: "Revisada",
  OPE: "Operada",
};

export let getOrdenesTrabajoStatuAssset = {
  GRA: {
    color: "primary_soft",
    class: "primary--text",
  },
  REV: {
    color: "secondary lighten-5",
    class: "secondary--text",
  },
  OPE: {
    color: "pla_c3_soft",
    class: "pla_c3--text text--darken-2",
  },
};
