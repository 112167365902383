<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card class="pa-2">
      <v-card-title>
        <span class="text-h6 support--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="colForm pa-2">
                <v-autocomplete
                  v-model="item.sinv_pro_id"
                  :items="productosEntrada"
                  color="support lighten-2"
                  label="Producto"
                  item-text="sinv_pro_descripcion"
                  item-value="sinv_pro_id"
                  clearable
                  hint="opcional"
                  persistent-hint
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Peso"
                  v-model="item.propsPeso"
                  hide-details="auto"
                  :rules="floatRules"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" :loading="loadingSave" text color="support">
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="loadingSave"
          color="primary"
        >
          {{ buttonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { findTipoProductoPlanilla } from "@/helpers";

export default {
  props: {
    itemIndex: Number,
    itemParam: Object,
    type: String,
  },
  data: () => ({
    valid: false,
    item: {
      sinv_pro_id: null,
      propsPeso: null,
      proprId: null,
    },
    floatRules: [
      (value) =>
        !value ||
        /(^$)|^[0-9]*$|^[0-9]*\.?[0-9]+?$/.test(value) ||
        "Ingresa un numero valido.",
    ],
    noEmptyRules: [(value) => !!value || "Completa este dato."],
    dialog: true,
  }),
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIndex > -1) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },
    findTipoProductoPlanilla(tipo) {
      return findTipoProductoPlanilla(tipo);
    },
    getProduct(id) {
      return this.productosSalida.find((product) => product.sinv_pro_id == id);
    },
    createItem() {
      const data = {
        sinv_pro_id: +this.item.sinv_pro_id,
        proprId: +localStorage.getItem("proprId"),
        propsPeso: +this.item.propsPeso,
      };
      localStorage.setItem(
        `sinv_pro_descripcion_temp`,
        this.getProduct(data.sinv_pro_id).sinv_pro_descripcion
      );
      this.$store
        .dispatch("productosSalida/createOrdenProduccionSalida", {
          data: data,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    updateItem() {
      const data = {
        propsId: +this.item.propsId,
        sinv_pro_id: +this.item.sinv_pro_id,
        proprId: +localStorage.getItem("proprId"),
        propsPeso: +this.item.propsPeso,
      };
      this.$store
        .dispatch("productosSalida/updateOrdenProduccionSalida", {
          data: data,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$store.dispatch("productosSalida/getProcesosProductosSalida");
    this.item.proprId = localStorage.getItem("proprId");
    this.item = this.itemParam;
    const id = this.$route.params.id;
    if (id === undefined) return;
    this.item.prprcId = id;
  },
  computed: {
    ...mapState({
      productosSalida: (state) => state.productosSalida.productosSalidaPro,
      loadingSave: (state) => state.tprocesos.loadingSave,
    }),
    formTitle() {
      return this.findTipoProductoPlanilla(this.type);
    },
    buttonTitle() {
      return this.itemIndex === -1 ? "Guardar" : "Actualizar";
    },
    productosEntrada() {
      return this.productosSalida
        .slice()
        .sort((a, b) =>
          a.sinv_pro_descripcion > b.sinv_pro_descripcion
            ? 1
            : b.sinv_pro_descripcion > a.plcsinv_pro_descripciontrNombre
            ? -1
            : 0
        );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
