<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card>
      <v-card-title class="secondary">
        <v-icon color="pla_c3"> {{ iconTitle }} </v-icon>
        <span class="text-h6 pl-3 white--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="py-2 px-5">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" class="colForm pa-2">
                <v-autocomplete
                  :loading="loading"
                  :items="employees"
                  v-model="item.plempId"
                  :filter="employeeFilter"
                  item-value="plempId"
                  label="Empleado"
                  dense
                  outlined
                  no-data-text="No se encontraron empleados."
                  messages="Busca por código, nombre o puesto."
                >
                  <template v-slot:selection="data">
                    <small>
                      {{ data.item.plempCodigo }}
                      <span class="font-weight-bold">/</span>
                      {{ data.item.plempNombres }}
                      {{ data.item.plempApellidos }}
                    </small>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          <small class="font-weight-bold">
                            {{ data.item.plempCodigo }}
                            <span class="font-weight-bold">/</span>
                            {{ data.item.plempNombres }}
                            {{ data.item.plempApellidos }}
                          </small>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small>
                            <span
                              class="
                                support--text
                                text--lighten-1
                                font-weight-bold
                              "
                            >
                              {{ data.item.plempPuesto || "" }}
                            </span>
                          </small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn @click="close" :loading="loadingSave" color="support" outlined>
          Cancelar
        </v-btn>
        <v-btn
          @click="save"
          elevation="0"
          :loading="loadingSave"
          color="primary"
        >
          {{ buttonTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { findTipoProductoPlanilla } from "@/helpers";

export default {
  props: {
    itemIndex: Number,
    itemParam: Object,
    type: String,
  },
  data: () => ({
    valid: false,
    item: {
      plempId: null,
      proprId: null,
    },
    noEmptyRules: [(value) => !!value || "Completa este dato."],
    dialog: true,
  }),
  methods: {
    save() {
      if (!this.$refs.form.validate()) return;
      if (this.itemIndex > -1) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },
    findTipoProductoPlanilla(tipo) {
      return findTipoProductoPlanilla(tipo);
    },
    createItem() {
      const data = {
        proprId: +localStorage.getItem("proprId"),
        plempId: +this.item.plempId,
      };
      this.$store
        .dispatch("employees/createEmployee", {
          data: data,
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    updateItem() {
      this.$store
        .dispatch("employees/updateEmployee", {
          data: {
            proprId: +localStorage.getItem("proprId"),
            plempId: +this.item.plempId,
            proptId: +this.item.proptId,
          },
        })
        .then(() => this.close())
        .catch((error) => console.log(error));
    },
    close() {
      this.$emit("close");
    },
    employeeFilter(item, queryText) {
      const codigo = item.plempCodigo.toString();
      const nombres = item.plempNombres.toLowerCase();
      const apellidos = item.plempApellidos.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        codigo.indexOf(searchText) > -1 ||
        nombres.indexOf(searchText) > -1 ||
        apellidos.indexOf(searchText) > -1
      );
    },
  },
  mounted() {
    this.item = this.itemParam;
    const id = this.$route.params.id;
    if (id === undefined) return;
    this.item.prprcId = id;
  },
  computed: {
    ...mapState({
      loadingSave: (state) => state.employees.loadingSave,
      employees: (state) => state.employees.employees,
      loading: (state) => state.employees.loading,
    }),
    formTitle() {
      return this.findTipoProductoPlanilla(this.type);
    },
    buttonTitle() {
      return this.itemIndex === -1 ? "Guardar" : "Actualizar";
    },
    iconTitle() {
      return this.itemIndex === -1 ? "mdi-plus" : "mdi-pen";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
